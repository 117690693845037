import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const apiIdentity = `${environment.baseUrl}/api/identity`;
const apiPublic = `${environment.baseUrl}/api/public`;
const apiClient = `${environment.baseUrl}/api/client`;
const apiWorker = `${environment.baseUrl}/api/worker`;
const apiSearch = `${environment.baseUrl}/api/search`;
const apiProposal = `${environment.baseUrl}/api/proposal`;
const apiDocument = `${environment.baseUrl}/api/document`;
const apiNotification = `${environment.baseUrl}/api/notification`;
const apiPayment = `${environment.baseUrl}/api/payment`;
const apiServInterviews = `${apiClient}/interviews/zoom`;

const baseUrl = `${environment.baseUrl}`;

export interface BackendConfig {
  api: ApiConfig[];
}
export interface ApiConfig {
  name: string;
  method: 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH';
  url: string;
  headers?: HttpHeaders | { [header: string]: string | string[]; };
  timeout?: number;
}
export const backendApi: ApiConfig[] = [
  {
    name: 'paymentResultNew',
    method: 'POST',
    url: apiClient + '/contracts/payment',
  },
  {
    name: 'getLinkedInAccessToken',
    method: 'POST',
    url: 'https://www.linkedin.com/oauth/v2/accessToken',
  },
  {
    name: 'getLinkedInAuthCode',
    method: 'GET',
    url: 'https://www.linkedin.com/oauth/v2/authorization',
  },
  {
    name: 'socialRegistrationLogin',
    method: 'POST',
    url: apiIdentity + '/api/register/workerSocial',
  },
  {
    name: 'socialLogin',
    method: 'POST',
    url: apiIdentity + '/api/social-login',
  },
  {
    name: 'uploadVideoCV',
    method: 'PUT',
    url: apiWorker + '/videocv',
  },
  {
    name: 'uploadCV',
    method: 'PUT',
    url: apiWorker + '/cv',
  },
  {
    name: 'getVideoCV',
    method: 'GET',
    url: apiDocument + '/v1/downLoad/videocvurlworker',
  },
  {
    name: 'getVideoCVCandidate',
    method: 'GET',
    url: apiDocument + '/v1/downLoad/videocvurlclient/{workerId}',
  },
  {
    name: 'getCVCandidate',
    method: 'GET',
    url: apiDocument + '/v1/downLoad/getCVOffuscatoUrlForClient/{workerId}',
  },
  {
    name: 'getCandidateDossier',
    method: 'GET',
    url: apiSearch + '/api/client/v1/download-dossier/{workerId}',
  },
  {
    name: 'getGCard',
    method: 'GET',
    url: apiPublic + '/api/v1/getGCard',
  },
  {
    name: 'getGCardReason',
    method: 'GET',
    url: apiPublic + '/api/v1/getGCardReason',
  },
  {
    name: 'downloadOffer',
    method: 'POST',
    url: apiDocument + '/v1/downLoad/downloadOffer/{offerUuid}',
  },
  {
    name: 'updateOfferNotificationFrequency',
    method: 'PUT',
    url: apiClient + '/api/v1/offer-notification',
  },
  {
    name: 'downloadContract',
    method: 'POST',
    url: apiDocument + '/v1/downLoad/downloadContract/{contractUuid}',
  },
  {
    name: 'downloadContractExt',
    method: 'POST',
    url: apiDocument + '/v1/downLoad/downloadContractExt/{documentaryId}',
  },
  {
    name: 'registerCompany',
    method: 'POST',
    url: apiIdentity + '/api/register/client',
  },
  {
    name: 'refreshToken',
    method: 'POST',
    url: apiIdentity + '/api/refresh-token',
  },
  {
    name: 'registerWorker',
    method: 'POST',
    url: apiIdentity + '/api/register/worker',
  },
  {
    name: 'registerWorkerProfile',
    method: 'POST',
    url: apiWorker + '/profile',
  },
  {
    name: 'linkedInLogin',
    method: 'POST',
    url: apiIdentity + '/api/linkedin-login',
  },
  {
    name: 'getUserStatus',
    method: 'GET',
    url: apiIdentity + '/api/user/v1/role',
  },
  {
    name: 'getCompanyData',
    method: 'GET',
    url: apiClient + '/api/v1/client',
  },
  {
    name: 'updateBillingInfo',
    method: 'PUT',
    url: apiClient + '/billing-information',
  },
  {
    name: 'updateWorkLocations',
    method: 'PUT',
    url: apiClient + '/work-locations',
  },
  {
    name: 'updateSearchCandidatePreferences',
    method: 'PUT',
    url: apiClient + '/api/v1/client/preferences',
  },
  {
    name: 'updateSolicitorDocument',
    method: 'PATCH',
    url: apiClient + '/api/v1/document',
  },
  {
    name: 'getAdditionalCompanyData',
    method: 'GET',
    url: apiClient + '/api/v1/client-data',
  },
  {
    name: 'acceptedOffers',
    method: 'GET',
    url: `${environment.baseUrl}/api/client/api/v1/offers/accepted`,
  },
  {
    name: 'nonFailedOffers',
    method: 'GET',
    url: `${environment.baseUrl}/api/client/api/v1/offers/non-failed`,
  },
  {
    name: 'createCompanyOfferts',
    method: 'POST',
    url: apiClient + '/api/v1/offer',
  },
  {
    name: 'getContractList',
    method: 'GET',
    url: apiClient + '/v1/contract',
    timeout: 1200000
  },
  {
    name: 'getFilteredContractList',
    method: 'POST',
    url: apiClient + '/client-contracts/filtered',
    timeout: 1200000
  },
  {
    name: 'getCheckRenewType',
    method: 'GET',
    url: apiClient + '/v1/check-renew-type/{contractUuid}',
  },
  {
    name: 'getCheckRenewTypeNew',
    method: 'GET',
    url: apiClient + '/contracts/{clientContractWnId}/extension-renew',
  },
  {
    name: 'addCasual', // DOESN'T EXIST ON BE!!!!
    method: 'POST',
    url: apiClient + '/v2/add-casual',
  },
  {
    name: 'addCheckExtensionOld',
    method: 'POST',
    url: apiClient + '/v1/check-extension',
  },
  {
    name: 'addCheckExtensionNew',
    method: 'POST',
    url: apiClient + '/contracts/extensions',
  },
  {
    name: 'createContractRenew',
    method: 'POST',
    url: apiClient + '/contracts/renewals',
  },
  {
    name: 'rejectAvailabilityRenewal',
    method: 'DELETE',
    url: apiWorker + '/contracts',
  },
  {
    name: 'getContractDetail',
    method: 'GET',
    url: apiClient + '/v1/contract/{wnId}',
  },
  {
    name: 'requestInterview',
    method: 'POST',
    url: apiClient + '/interview/in-person/{candidateArcaId}',
  },
  {
    name: 'requestVideoInterview',
    method: 'POST',
    url: apiClient + '/interview/video/{candidateArcaId}',
  },
  {
    name: 'getContractWorkerList',
    method: 'GET',
    url: apiWorker + '/v1/contract',
  },
  {
    name: 'getContractWorkerDetail',
    method: 'GET',
    url: apiWorker + '/v1/contract/{wnId}',
  },
  {
    name: 'getDocumentsType',
    method: 'GET',
    url: apiPublic + '/api/v1/documentTypes',
  },
  {
    name: 'getCcnlList',
    method: 'GET',
    url: apiPublic + '/api/v1/ccnl',
  },
  {
    name: 'getAtecoList',
    method: 'GET',
    url: apiPublic + '/api/v1/activity',
  },
  {
    name: 'completeCompanyProfile',
    method: 'POST',
    url: apiClient + '/main-data',
  },
  {
    name: 'completeCompanyRegistration',
    method: 'POST',
    url: apiClient + '/profile-data',
  },
  {
    name: 'getTaskWorker',
    method: 'GET',
    url: apiPublic + '/api/v1/task',
  },
  {
    name: 'getCcnlLevels',
    method: 'GET',
    url: apiPublic + '/api/v1/ccnl-level',
  },
  {
    name: 'getJobs',
    method: 'GET',
    url: apiPublic + '/api/v1/job',
  },
  {
    name: 'getListGroundForAppeal',
    method: 'GET',
    url: apiPublic + '/api/v1/ground-for-appeal',
  },
  {
    name: 'getCausGroundForAppeal',
    method: 'GET',
    url: apiPublic + '/api/v1/caus-ground-for-appeal',
  },
  {
    name: 'getListOfWorkingPlaces',
    method: 'GET',
    url: apiClient + '/api/v1/worker-place',
  },
  {
    name: 'createOffertSimulation',
    method: 'POST',
    url: apiClient + '/api/v1/offer-simulation/{offerUuid}',
  },
  {
    name: 'getOffertDetail',
    method: 'GET',
    url: apiClient + '/api/v1/offer',
  },
  {
    name: 'getAnnouncementList',
    method: 'GET',
    url: apiClient + '/api/v1/job-offer',
  },
  {
    name: 'getAnnouncementDetail',
    method: 'GET',
    url: apiClient + '/api/v1/job-offer',
  },
  {
    name: 'updateAnnouncementNotificationFrequency',
    method: 'PUT',
    url: apiClient + '/api/v2/job-offer/notification-frequency',
  },
  {
    name: 'createAnnouncement',
    method: 'POST',
    url: apiClient + '/api/v2/job-offer',
  },
  {
    name: 'editAnnouncement',
    method: 'PUT',
    url: apiClient + '/api/v2/job-offer',
  },
  {
    name: 'changeAnnouncementStatus',
    method: 'PATCH',
    url: apiClient + '/api/v1/job-offer',
  },
  {
    name: 'getJobApplications',
    method: 'GET',
    url: apiClient + '/search/job-applications/{jobOfferUuid}',
  },
  {
    name: 'patchJobApplicationFeedback',
    method: 'PATCH',
    url: apiClient + '/job-applications/{jobApplicationUuid}/feedback',
  },
  {
    name: 'deleteWorker',
    method: 'DELETE',
    url: apiIdentity + '/api/delete/v1/worker',
  },
  {
    name: 'deleteClient',
    method: 'DELETE',
    url: apiIdentity + '/api/delete/v1/client',
  },
  {
    name: 'acceptOffer',
    method: 'PUT',
    url: apiClient + '/api/v1/offer/{commercialOfferUuid}',
  },
  {
    name: 'getUserWorkerStatus',
    method: 'GET',
    url: apiWorker + '/status/me',
  },
  {
    name: 'getWorkerData',
    method: 'GET',
    url: apiWorker + '/api/v1/profile',
  },
  {
    name: 'editWorkerPersonalData',
    method: 'PUT',
    url: apiWorker + '/profile/personal',
  },
  {
    name: 'getWorkerProfileCounters',
    method: 'GET',
    url: apiWorker + '/api/v1/worker-data',
  },
  {
    name: 'addNonEUDocument',
    method: 'PUT',
    url: apiWorker + '/extra-eu-documents',
  },
  {
    name: 'uploadWorkerDocument',
    method: 'PUT',
    url: apiWorker + '/documents',
  },
  {
    name: 'uploadClientDocument',
    method: 'PUT',
    url: apiClient + '/documents'
  },
  {
    name: 'downloadClientDocument',
    method: 'GET',
    url: apiDocument + '/v1/downLoad/downloadDocument/{uuid}',
  },
  {
    name: 'downloadWorkerDocument',
    method: 'GET',
    url: apiDocument + '/v1/downLoad/downloadDocument/{uuid}',
  },
  {
    name: 'downloadCv',
    method: 'GET',
    url: apiWorker + '/cv/download',
  },
  {
    name: 'downloadWorkerPermesso',
    method: 'GET',
    url: apiWorker + '/extra-eu-documents/residence-permit/download',
  },
  {
    name: 'downloadWorkerRinnovo',
    method: 'GET',
    url: apiWorker + '/extra-eu-documents/renewal-receipt/download',
  },

  {
    name: 'getWorkerDocuments',
    method: 'GET',
    url: apiWorker + '/documents',
  },
  {
    name: 'getWorkerDocumentDetail',
    method: 'GET',
    url: apiWorker + '/api/v2/private/document/{documentType}',
  },
  {
    name: 'getWorkerNonUeDocumentDetail',
    method: 'GET',
    url: apiWorker + '/extra-eu-documents',
  },
  {
    name: 'getWorkerNonUeDocument',
    method: 'GET',
    url: apiWorker + '/extra-eu-documents/info',
  },
  {
    name: 'getWorkerCurriculum',
    method: 'GET',
    url: apiWorker + '/api/v2/private/curriculum',
  },
  {
    name: 'getWorkerHasCurriculum',
    method: 'GET',
    url: apiWorker + '/cv',
  },
  {
    name: 'getWorkerHasVideoCurriculum',
    method: 'GET',
    url: apiWorker + '/videocv',
  },
  {
    name: 'getWorkerCurriculumPublic',
    method: 'GET',
    url: apiWorker + '/api/v2/public/curriculum',
  },
  {
    name: 'upsertWorkerCurriculum',
    method: 'PUT',
    url: apiWorker + '/api/v2/curriculum',
  },
  {
    name: 'requestSignature',
    method: 'GET',
    url: baseUrl + '/api/signature',
  },
  {
    name: 'mailValidation',
    method: 'POST',
    url: apiIdentity + '/api/mail-validation',
  },
  {
    name: 'getCompanyFilters',
    method: 'GET',
    url: apiSearch + '/api/client/v1/search-filter',
  },
  {
    name: 'getCompanySearch',
    method: 'GET',
    url: apiClient + '/search',
  },
  {
    name: 'getPublicCompanySearch',
    method: 'GET',
    url: apiPublic + '/search/candidates',
  },
  {
    name: 'getPublicCompanySearchSeo',
    method: 'GET',
    url: apiPublic + '/search/candidates/seo',
  },
  {
    name: 'getPublicCompanyFilters',
    method: 'GET',
    url: apiSearch + '/api/public/v1/search-filter-candidate',
  },
  {
    name: 'getSearchWorkerDatail',
    method: 'GET',
    url: apiSearch + '/api/client/v1/search-detail',
  },
  {
    name: 'getFavorite',
    method: 'GET',
    url: apiSearch + '/api/client/v1/favorite',
  },
  {
    name: 'saveFavorite',
    method: 'PATCH',
    url: apiSearch + '/api/client/v1/favorite',
  },
  {
    name: 'getContractSummary',
    method: 'GET',
    url: apiClient + '/v1/contract-summary/{proposalUuid}',
  },
  {
    name: 'getFinalContractSummary',
    method: 'GET',
    url: apiClient + '/v1/final-contract-summary/{proposalUuid}',
  },
  {
    name: 'getAvailabilityRecap',
    method: 'POST',
    url: apiClient + '/contracts/recap',
  },
  {
    name: 'getExtensionRecap',
    method: 'POST',
    url: apiClient + '/contracts/extension/recap',
  },
  {
    name: 'getClientProposalsNumber',
    method: 'GET',
    url: apiProposal + '/api/v1/proposal/client/to-read',
  },
  {
    name: 'getClientConfig',
    method: 'GET',
    url: apiClient + '/api/v1/client-config',
  },
  {
    name: 'getUserCompanyStatus',
    method: 'GET',
    url: apiClient + '/status/me',
  },
  {
    name: 'getClientDelegations',
    method: 'GET',
    url: `${environment.baseUrl}/api/delegates/clients/@self/delegations/`,
  },
  {
    name: 'removeClientDelegations',
    method: 'PUT',
    url: `${environment.baseUrl}/api/delegates/clients/@self/delegations/`,
  },
  {
    name: 'findClientDelegate',
    method: 'POST',
    url: `${environment.baseUrl}/api/delegates/clients/@self/delegation-requests/by-email/`,
  },
  {
    name: 'inviteClientDelegate',
    method: 'POST',
    url: `${environment.baseUrl}/api/delegates/clients/@self/delegation-requests/`,
  },
  {
    name: 'deleteAndReplaceDelegationRequest',
    method: 'POST',
    url: `${environment.baseUrl}/api/delegates/clients/@self/delegation-requests/{delegateId}/by-email/`,
  },
  {
    name: 'deleteAndReplaceDelegationRequestForNotExistingDelegate',
    method: 'POST',
    url: `${environment.baseUrl}/api/delegates/clients/@self/delegation-requests/{delegateId}/`,
  },
  {
    name: 'activateDelegate',
    method: 'POST',
    url: `${environment.baseUrl}/api/delegates/activate-delegate`,
  },
  {
    name: 'getDelegate',
    method: 'GET',
    url: `${environment.baseUrl}/api/delegates/@self/`,
  },
  {
    name: 'impersonateClient',
    method: 'POST',
    url: `${environment.baseUrl}/api/delegates/impersonations/clients/{clientId}/`,
  },
  {
    name: 'deimpersonateClient',
    method: 'POST',
    url: `${environment.baseUrl}/api/delegates/impersonations/pop/`,
  },
  {
    name: 'getCcnlClient',
    method: 'GET',
    url: apiClient + '/api/v1/offer-ccnl',
  },
  {
    name: 'getRepetitionReasons',
    method: 'GET',
    url: apiClient + '/contracts/repetition-reasons/{ccnlCode}',
  },
  {
    name: 'getWorkersFilters',
    method: 'GET',
    url: apiSearch + '/api/worker/v1/search-filter',
  },
  {
    name: 'workerSearch',
    method: 'GET',
    url: apiSearch + '/api/worker/v1/search',
  },
  {
    name: 'getSearchWorkerDetail',
    method: 'GET',
    url: apiSearch + '/api/worker/v1/search-detail',
  },
  {
    name: 'getPublicSearchWorkerDetail',
    method: 'GET',
    url: apiSearch + '/api/public/v1/search-detail',
  },
  {
    name: 'saveWorkerFavorite',
    method: 'PATCH',
    url: apiSearch + '/api/worker/v1/favorite',
  },
  {
    name: 'getFavoriteAnnouncements',
    method: 'GET',
    url: apiSearch + '/api/worker/v1/favorite',
  },
  {
    name: 'sendApplication',
    method: 'POST',
    url: apiProposal + '/api/v1/proposal/worker/job-application',
  },
  {
    name: 'getSentApplications',
    method: 'GET',
    url: apiProposal + '/api/v1/proposal/worker/job-application',
  },
  {
    name: 'getApplicatons',
    method: 'GET',
    url:
      apiProposal + '/api/v1/proposal/client/job-application/{jobOfferArcaId}',
  },
  {
    name: 'publicWorkerSearch',
    method: 'GET',
    url: apiSearch + '/api/public/v1/search',
  },
  {
    name: 'getPublicWorkersFilters',
    method: 'GET',
    url: apiSearch + '/api/public/v1/search-filter',
  },
  {
    name: 'forgotPassword',
    method: 'POST',
    url: apiIdentity + '/api/forgot-password',
  },
  {
    name: 'resetPassword',
    method: 'POST',
    url: apiIdentity + '/api/reset-password',
  },
  {
    name: 'getDocuments',
    method: 'GET',
    url: apiWorker + '/api/v1/getDocContrattualiList',
  },
  {
    name: 'getUnionsNameList',
    method: 'GET',
    url: apiPublic + '/api/v1/sindacati',
  },
  {
    name: 'getUnionsCodList',
    method: 'GET',
    url: apiPublic + '/api/v1/sediSindacato/{codSindacato}',
  },
  {
    name: 'getUnions',
    method: 'GET',
    url: apiWorker + '/api/v1/getSindacatiInseritiDaUtenteList',
  },
  {
    name: 'saveUnions',
    method: 'POST',
    url: apiWorker + '/api/v2/aggiuntaSceltaSindacato',
  },
  {
    name: 'getSpecificDocument',
    method: 'GET',
    url: apiWorker + '/api/v1/getContractDocument/{tipoDocumento}',
  },
  {
    name: 'saveTaxDeductions',
    method: 'POST',
    url: apiWorker + '/api/v1/detrazioneImposta',
  },
  {
    name: 'getFamilyStatus',
    method: 'GET',
    url: apiWorker + '/api/v1/getFamigliariInseritiList',
  },
  {
    name: 'saveFamilyStatus',
    method: 'POST',
    url: apiWorker + '/api/v1/statoFamigliaResidenza',
  },
  {
    name: 'getDocumentsSelectValueList',
    method: 'GET',
    url: apiWorker + '/api/v1/getFieldListFe/{fieldType}',
  },
  {
    name: 'getForeignBank',
    method: 'GET',
    url: apiPublic + '/api/v1/foreignBank',
  },
  {
    name: 'saveBankData',
    method: 'POST',
    url: apiWorker + '/api/v1/datiPagamento',
  },
  {
    name: 'saveUnemploymentStatusData',
    method: 'POST',
    url: apiWorker + '/api/v1/autocertifDisoccupazione',
  },
  {
    name: 'getPensionsFund',
    method: 'GET',
    url: apiPublic + '/api/v1/getPensionFund',
  },
  {
    name: 'saveTfrData',
    method: 'POST',
    url: apiWorker + '/api/v1/sceltaTfr',
  },
  {
    name: 'getNotifications',
    method: 'GET',
    url: apiNotification + '/view/list',
  },
  {
    name: 'updateNotificationStatus',
    method: 'POST',
    url: apiNotification + '/view/updateStatus',
  },
  {
    name: 'getNotificationsNumber',
    method: 'GET',
    url: apiNotification + '/view/toRead',
  },
  {
    name: 'getRiskRegistry',
    method: 'GET',
    url: apiClient + '/api/v1/risk-registry',
  },
  {
    name: 'saveRiskRegistry',
    method: 'POST',
    url: apiClient + '/api/v1/risk-registry',
  },
  {
    name: 'getRiskList',
    method: 'GET',
    url: apiClient + '/api/v1/risk-board',
  },
  {
    name: 'getRiskSheetInfo',
    method: 'GET',
    url: apiClient + '/api/v1/risk-board-data',
  },
  {
    name: 'getRiskSheet',
    method: 'GET',
    url: apiClient + '/api/v1/risk-board',
  },
  {
    name: 'saveRiskSheet',
    method: 'POST',
    url: apiClient + '/api/v2/risk-board',
  },
  {
    name: 'saveContract',
    method: 'POST',
    url: apiClient + '/v1/contract',
  },
  {
    name: 'saveExtensionContract',
    method: 'POST',
    url: apiClient + '/v1/extension',
  },
  {
    name: 'getWorkList',
    method: 'GET',
    url: apiClient + '/v1/to-work',
  },
  {
    name: 'getWorkerWorkList',
    method: 'GET',
    url: apiWorker + '/v1/to-work',
  },
  {
    name: 'getPaysLipsLink',
    method: 'GET',
    url: apiWorker + '/api/v1/getSWEndpoint',
  },
  {
    name: 'getTimeSheetLink',
    method: 'GET',
    url: apiWorker + '/api/v1/getNEEndpoint',
  },
  {
    name: 'getClientTimeSheetLink',
    method: 'GET',
    url: apiClient + '/api/v1/getNEEndpoint',
  },
  {
    name: 'getInvoices',
    method: 'GET',
    url: apiClient + '/api/v1/getInvoices',
  },
  {
    name: 'downloadInvoice',
    method: 'GET',
    url: apiDocument + '/v1/downLoad/downloadInvoice/{documentalId}',
  },
  {
    name: 'generateWebChatToken',
    method: 'POST',
    url: 'https://directline.botframework.com/v3/directline/tokens/generate',
  },
  {
    name: 'sendRating',
    method: 'POST',
    url: apiClient + '/v1/rating',
  },
  {
    name: 'changeRating',
    method: 'PUT',
    url: apiClient + '/v1/rating',
  },
  {
    name: 'sendClientRating',
    method: 'POST',
    url: apiWorker + '/v1/rating',
  },
  {
    name: 'changeClientRating',
    method: 'PUT',
    url: apiWorker + '/v1/rating',
  },
  {
    name: 'getActiveOffert',
    method: 'GET',
    url: apiClient + '/api/v1/active-offer',
  },
  {
    name: 'savePhoto',
    method: 'PUT',
    url: apiWorker + '/profileImg',
  },
  {
    name: 'getPhoto',
    method: 'GET',
    url: apiDocument + '/v1/downLoad/photoUrlWorker',
  },
  // {
  //   name: 'getCompanyPhoto',
  //   method: 'GET',
  //   url: apiDocument + '/v1/downLoad/photo/client/{clientUuid}',
  // },
  {
    name: 'getCandidatePhoto',
    method: 'GET',
    url: apiDocument + '/v1/downLoad/photoUrlClient/{workerId}',
  },
  {
    name: 'getParteners',
    method: 'GET',
    url: apiPublic + '/api/v1/spotlight',
  },
  {
    name: 'getPartenersSearchPublic',
    method: 'GET',
    url: apiSearch + '/api/public/v1/brand/search',
  },
  {
    name: 'getPartenersSearch',
    method: 'GET',
    url: apiSearch + '/api/worker/v1/brand/search',
  },
  {
    name: 'sendSupportRequest',
    method: 'POST',
    url: apiPublic + '/api/v1/support',
  },
  {
    name: 'sendSalesforceContactForm',
    method: 'POST',
    url: 'https://innovation-connect-612--sit.sandbox.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8&orgId=00D1x0000001nCV',
  },
  {
    name: 'createConversation',
    method: 'POST',
    url: apiClient + '/conversations',
  },
  {
    name: 'getConversationsList',
    method: 'GET',
    url: apiClient + '/conversations',
  },
  {
    name: 'getConversationMessages',
    method: 'GET',
    url: apiClient + '/conversations/{conversationId}/messages',
  },
  {
    name: 'updateJob',
    method: 'PATCH',
    url: apiClient + '/conversations/{conversationId}/topic',
  },
  {
    name: 'sendMessage',
    method: 'POST',
    url: apiClient + '/conversations/{conversationId}/messages',
  },
  {
    name: 'suspendConversation',
    method: 'PATCH',
    url: apiClient + '/conversations/{conversationId}/suspend',
  },
  {
    name: 'reactivateConversation',
    method: 'PATCH',
    url: apiClient + '/conversations/{conversationId}/activate',
  },
  {
    name: 'getLastMessages',
    method: 'GET',
    url:
      apiClient +
      '/conversations/{conversationId}/messages/last-message/{messageId}',
  },
  {
    name: 'getConversationListDelegate',
    method: 'GET',
    url: apiClient + '/conversations/{conversationId}',
  },
  {
    name: 'getConversationListWorker',
    method: 'GET',
    url: apiWorker + '/conversations',
  },
  {
    name: 'getConversationMessagesWorker',
    method: 'GET',
    url: apiWorker + '/conversations/{conversationId}/messages',
  },
  {
    name: 'sendMessageWorker',
    method: 'POST',
    url: apiWorker + '/conversations/{conversationId}/messages',
  },
  {
    name: 'getLastMessagesWorker',
    method: 'GET',
    url:
      apiWorker +
      '/conversations/{conversationId}/messages/last-message/{messageId}',
  },
  {
    name: 'updateConversationNotificationFrequency',
    method: 'PUT',
    url: apiClient + '/conversations/notification',
  },
  {
    name: 'getWorkerByFiscalCode',
    method: 'GET',
    url: apiClient + '/search/by-fiscal-code',
  },
  {
    name: 'workerHasSignedContracts',
    method: 'GET',
    url: apiWorker + '/contracts/signed',
  },
  {
    name: 'getSuggestedJobOffers',
    method: 'GET',
    url: apiWorker + '/suggested-jobs',
  },
  {
    name: 'getSuggestedWorkersByLatestJobOffer',
    method: 'GET',
    url: apiClient + '/job-offers/latest/suggested-workers',
  },
  {
    name: 'getSuggestedWorkersByJobOffer',
    method: 'GET',
    url: apiClient + '/job-offers/{uuid}/suggested-workers',
  },
  {
    name: 'requestInterviewVideo',
    method: 'POST',
    url: apiClient + '/interviews',
  },
  {
    name: 'getInterviewsCompanyListFiltered',
    method: 'POST',
    url: apiClient + '/interviews/filtered',
  },
  {
    name: 'cancelInterviewCompany',
    method: 'DELETE',
    url: apiClient + '/interviews/{interviewId}',
  },
  {
    name: 'getInterviewsWorkerListFiltered',
    method: 'POST',
    url: apiWorker + '/interviews/filtered',
  },
  {
    name: 'confirmInterviewWorker',
    method: 'PATCH',
    url: apiWorker + '/interviews/{interviewId}',
  },
  {
    name: 'rejectInterviewWorker',
    method: 'PATCH',
    url: apiWorker + '/interviews/{interviewId}/rejected',
  },
  {
    name: 'cancelInterviewWorker',
    method: 'DELETE',
    url: apiWorker + '/interviews/{interviewId}',
  },
  {
    name: 'updateDeviceToken',
    method: 'PUT',
    url: baseUrl + '/api/firebase/v1/user/device-token'
  },
  {
    name: 'getInterviewFreeTimeSlots',
    method: 'GET',
    url: apiServInterviews + '/get-data/{daydate}'
  },
  {
    name: 'getPubAnnouncements',
    method: 'GET',
    url: apiSearch + '/api/public/v1/public-job-offers'
  },
  {
    name: 'periodValidityCheck',
    method: 'POST',
    url: apiClient + '/contracts/period-validity',
  },
  {
    name: 'periodValidityRenewalCheck',
    method: 'POST',
    url: apiClient + '/contracts/renewals/period-validity',
  },
  {
    name: 'periodValidityExtensionCheck',
    method: 'POST',
    url: apiClient + '/contracts/extensions/period-validity',
  },
  {
    name: 'getAvailabilityCompanyList',
    method: 'POST',
    url: apiClient + '/contracts/filtered',
  },
  {
    name: 'getAvailabilityWorkerList',
    method: 'POST',
    url: apiWorker + '/contracts/filtered',
  },
  {
    name: 'getAvailabilityWorkerDetail',
    method: 'GET',
    url: apiWorker + '/contracts/{id}',
  },
  {
    name: 'getAvailabilityCompanyDetail',
    method: 'GET',
    url: apiClient + '/contracts/{id}',
  },
  {
    name: 'sendAvailabilityRequest',
    method: 'POST',
    url: apiClient + '/contracts',
  },
  {
    name: 'acceptAvailabilityNew',
    method: 'POST',
    url: apiWorker + '/contracts/{id}',
  },
  {
    name: 'acceptAvailabilityExtension',
    method: 'POST',
    url: apiWorker + '/contracts/extensions/{id}',
  },
  { //eliminare
    name: 'acceptAvailabilityRenewal',
    method: 'POST',
    url: apiWorker + '/contracts/{id}',
  },
  {
    name: 'rejectAvailabilityNew',
    method: 'DELETE',
    url: apiWorker + '/contracts',
  },
  {
    name: 'rejectAvailabilityExtension',
    method: 'DELETE',
    url: apiWorker + '/contracts/extensions',
  },
  {
    name: 'initPaymentNew',
    method: 'POST',
    url: apiClient + '/contracts/{id}/payment',
  },
  {
    name: 'workerDocumentsToSign',
    method: 'GET',
    url: apiWorker + '/documents/toSign',
  },
  {
    name: 'clientDocumentsToSign',
    method: 'GET',
    url: apiClient + '/documents/toSign',
  }

];
export const backendConfig: Partial<BackendConfig> = {
  api: backendApi,
};
